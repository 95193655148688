"use client";

import { ToastAction } from "@radix-ui/react-toast";
import { ExternalLink, MinusIcon, PlusIcon, Trash2Icon } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import * as React from "react";

import { ClientOnly } from "@/components/client-only";
import parse from "@/components/html-parser";
import { Loading } from "@/components/icons/loading";
import { LockBadgeOverlay } from "@/components/lock";
import { StudySummaryActionBar } from "@/components/study-summary-action";
import Trial from "@/components/trial";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { toast } from "@/components/ui/use-toast";
import { fetchWrapper } from "@/lib/hooks/fetch-client";
import { cn, formatDate } from "@/lib/utils";

import { FreeTrialWrapper } from "./free-trial-wrapper";

export interface ResearchCardProps {
	summary: any;
	rules: {
		showContent: boolean;
		showQuickSummary: boolean;
		showActionbar: boolean;
		isResearchFeed: boolean;
		isSecondSummary: boolean;
		isFavorite?: boolean;
		isUser: boolean;
		hasAccess: boolean;
		setExpanded?: (expanded: boolean) => void;
		isExpanded?: boolean;
		view?: "simple" | "full" | string;
	};
	actionBar?: React.ReactNode;
	icons?: React.ReactNode[];
}

const specialCategories = [
	"cannabis-cbd",
	"diets-foods",
	"editors-picks",
	"herbal-supplements",
	"low-carb",
	"outside-the-box",
	"vegan-vegetarian",
	"vitamins-minerals",
];

type ResearchCardCtaProps = {
	hideBody: boolean;
	isSecondSummary: boolean;
};

const ResearchCardCtaHeader: React.FC<{ isSecondSummary: boolean }> = ({ isSecondSummary }) => {
	if (isSecondSummary) {
		return (
			<div>
				<p className="font-inter text-lg font-bold">
					Create a free account to unlock this study summary.{" "}
					<span className="font-normal">
						(
						<Link href="/login/" className="text-primary underline">
							Log in
						</Link>{" "}
						or{" "}
						<Link href="/signup/" className="text-primary underline">
							click here to create a free account.
						</Link>
						)
					</span>
				</p>
				<p className="w-full pb-6 pt-2 text-lg [@media(min-width:500px)]:w-[30ch]">
					If you want to stay on top of the latest research,{" "}
					<Link href="/plus/" className="text-primary underline">
						try out Examine+ free for <Trial />
					</Link>
					.
				</p>
			</div>
		);
	}

	return (
		<p className="font-inter text-lg font-bold">
			Full study summary unlocked for Examine+ members{" "}
			<span className="font-normal">
				(
				<Link href="/login/" className="text-primary underline">
					Log in
				</Link>
				)
			</span>
		</p>
	);
};

const ResearchCardCtaBody: React.FC<{ isSecondSummary: boolean }> = ({ isSecondSummary }) => {
	const description = isSecondSummary
		? ""
		: "Get the latest evidence-based nutrition and supplement information, presented by a team of experts. Save time and money by learning what works.";

	return (
		<>
			{description && (
				<p className="w-full pb-6 pt-2 text-lg [@media(min-width:500px)]:w-[30ch]">{description}</p>
			)}
			<Link
				href="/plus/"
				className="inline-block rounded-full bg-premium px-3 py-1.5 text-center text-base font-bold text-primary transition-colors hover:bg-primary hover:text-white sm:text-lg xl:px-5"
			>
				<FreeTrialWrapper cta="Get started with Examine+ today.">
					Try Examine+ free for <Trial />
				</FreeTrialWrapper>
			</Link>
		</>
	);
};

const ResearchCardCta: React.FC<ResearchCardCtaProps> = ({ hideBody, isSecondSummary }) => {
	return (
		<div className="pointer-events-auto">
			<ResearchCardCtaHeader isSecondSummary={isSecondSummary} />
			{!hideBody && <ResearchCardCtaBody isSecondSummary={isSecondSummary} />}
		</div>
	);
};

const MaskContentSection = ({
	condition,
	section,
	children,
	overlayText,
	content,
	hideBody = false,
}: any) => {
	if (condition) return children;

	const generateDummyText = (length: number): string => {
		const words = [
			"the",
			"quick",
			"brown",
			"fox",
			"jumps",
			"over",
			"lazy",
			"dog",
			"and",
			"runs",
			"away",
		];
		let sentence = "";
		while (sentence.length < length) {
			sentence += words[Math.floor(Math.random() * words.length)] + " ";
		}
		sentence = sentence.trim();
		return sentence.charAt(0).toUpperCase() + sentence.slice(1) + ".";
	};
	const generateFixedText = (length: number): string => {
		const words = [
			"the",
			"quick",
			"brown",
			"fox",
			"jumps",
			"over",
			"lazy",
			"dog",
			"and",
			"runs",
			"away",
		];
		let sentence = "";
		let i = 0;
		while (sentence.length < length) {
			sentence += words[i % words.length] + " ";
			i++;
		}
		sentence = sentence.trim();
		return sentence.charAt(0).toUpperCase() + sentence.slice(1) + ".";
	};

	const convertString = (htmlString: string): string => {
		// Function to generate a random alphabet letter
		const randomLetter = () =>
			String.fromCharCode(Math.floor(Math.random() * 26) + (Math.random() > 0.5 ? 65 : 97));

		// Function to generate a random digit
		const randomDigit = () => Math.floor(Math.random() * 10).toString();

		// Convert characters outside HTML tags
		return htmlString?.replace(
			/(>)([^<]+)(<)/g,
			(_, startTag, content, endTag) =>
				startTag +
				content.replace(/[a-zA-Z]/g, () => randomLetter()).replace(/\d/g, () => randomDigit()) +
				endTag
		);
	};

	return (
		<div className="space-y-4 -tracking-2 lg:space-y-6 xl:text-xl xl:leading-9">
			<ClientOnly>
				{section ? <p className="mb-4 text-lg font-bold xl:text-xl">{section}</p> : null}
				<div className={cn("relative ", hideBody ? "min-h-28" : "min-h-64")}>
					<LockBadgeOverlay
						size={10}
						text={overlayText}
						className="z-[1] w-full bg-white [@media(min-width:500px)]:w-auto"
					/>
					<div className="pointer-events-none lowercase text-gray-500 blur-sm [&>p]:text-gray-500">
						{content ? parse(convertString(content)) : generateFixedText(350)}
					</div>
				</div>
			</ClientOnly>
		</div>
	);
};

export default function ResearchCard({ summary, rules }: ResearchCardProps) {
	const {
		id,
		headline,
		created_date,
		conditions,
		interventions,
		study_url,
		tags,
		categories,
		primary_category,
		new: is_new,
		v2,
		format: version,
		blurb,
	} = summary;

	const {
		showContent,
		showQuickSummary,
		showActionbar,
		isResearchFeed,
		isSecondSummary,
		isFavorite,
		isUser,
		hasAccess,
		setExpanded,
		isExpanded,
		view = "full",
	} = rules;

	const pathname = usePathname();
	const [tagsCount, setTagsCount] = React.useState(3);
	const [loading, setLoading] = React.useState(false);
	const [fullSummary, setFullSummary] = React.useState<any>(null);

	const slug = pathname.split("/").pop();
	const allCategories = [...categories, primary_category];
	const uniqueCategories = allCategories.filter(
		(tag, index, array) => array.findIndex((t) => t?.slug == tag?.slug) == index
	);
	const allTags = [...tags, ...uniqueCategories, ...conditions, ...interventions];
	const filteredTags = allTags
		?.filter((item) => item?.slug !== slug)
		.filter((item) => item?.slug !== "editorspick");

	const getTagLink = (item: any, isResearchFeed: any) => {
		if (item.tag) {
			return `/research-feed/filter/?tag=${item.slug}`;
		}

		if (item.url) {
			return isResearchFeed
				? `/research-feed/filter/?filter=${(
						item.intervention_type ||
						item.type ||
						item.url.split("/")[1].slice(0, -1)
				  )?.toLowerCase()}s&value=${item.slug}`
				: item.url;
		}

		return isResearchFeed || specialCategories.includes(item.slug)
			? `/research-feed/filter/?filter=categories&value=${item.slug}`
			: `/categories/${item.slug}/`;
	};

	const removeFavorite = async (id: any) => {
		if (!confirm("Are you sure?")) return;

		await fetchWrapper(`/v1/study-summaries/favorites/${id}`, {
			method: "DELETE",
		})
			.then((res: any) => {
				if (res) {
					location.reload();
					toast({
						title: "Removed!",
					});
				}
			})
			.catch((error) => {
				toast({
					title: "Error!",
					description: error.message,
					action: (
						<ToastAction altText="Try again" onClick={() => removeFavorite(id)}>
							Try again
						</ToastAction>
					),
					variant: "error",
				});
			});
	};

	React.useEffect(() => {
		const updateNumTagsToShow = () => {
			if (window.innerWidth < 768) {
				setTagsCount(3); // Show three tags on mobile
			} else {
				setTagsCount(filteredTags?.length); // Show all tags on larger screens
			}
		};
		updateNumTagsToShow();
		window.addEventListener("resize", updateNumTagsToShow);

		return () => {
			window.removeEventListener("resize", updateNumTagsToShow);
		};
	}, [filteredTags]);

	React.useEffect(() => {
		async function getSummary() {
			setLoading(true);

			await fetchWrapper(`/v1/study-summaries/summary/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res: any) => {
					if (!res) {
						return null;
					}
					setFullSummary(res.data.summary);
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		}

		if (isExpanded && !fullSummary) {
			setLoading(true);
			getSummary();
		}
	}, [fullSummary, id, isExpanded]);

	return (
		<Collapsible
			className={cn(view === "full" ? "rounded-md border border-gray-200 p-6" : "p-3")}
			defaultOpen={false}
			open={isExpanded}
			onOpenChange={setExpanded}
		>
			<CollapsibleTrigger asChild>
				<div>
					<div className="group relative flex items-baseline justify-between gap-3 rounded-md py-1">
						<div className="flex flex-col gap-1 text-right">
							<span
								onClick={() => setExpanded(!isExpanded)}
								className="mx-auto grid size-5 shrink-0 cursor-pointer place-content-center rounded-full border border-primary text-primary group-hover:bg-primary group-hover:text-white xl:size-6 xl:border-2"
								title="Expand for full summary"
							>
								{isExpanded ? (
									<MinusIcon className="size-3 xl:size-3.5" />
								) : (
									<PlusIcon className="size-3 xl:size-3.5" />
								)}
							</span>
						</div>
						<div
							onClick={() => setExpanded(!isExpanded)}
							className="flex w-full cursor-pointer flex-wrap items-center gap-3 text-start font-semibold text-examine-purple-500 md:text-lg lg:text-gray-900 lg:group-hover:text-primary xl:text-[1.35rem]"
						>
							<span>{parse(headline)}</span>
							{is_new && !pathname.includes("research-feed") ? (
								<span className="view-page rounded-full bg-examine-yellow-premium px-2 py-1 font-sans text-xs font-semibold">
									New
								</span>
							) : null}

							{hasAccess && isExpanded ? (
								<a
									href={study_url}
									target="_blank"
									rel="noreferrer"
									onClick={(e) => e.stopPropagation()}
									className="box-border inline-flex flex-nowrap items-center gap-0.5 whitespace-nowrap rounded-full border border-info px-3 py-1 text-xs font-medium  text-primary transition-colors duration-200 hover:bg-primary hover:text-white active:bg-examine-purple-700"
								>
									Original paper <ExternalLink className="size-4 stroke-1" />
								</a>
							) : null}
						</div>
						{isFavorite && (
							<button onClick={() => removeFavorite(summary.id)}>
								<Trash2Icon className=" text-gray-500 hover:text-primary" />
							</button>
						)}
					</div>
					<div className="flex flex-wrap items-center gap-1 py-2 text-sm font-medium text-examine-green-600  xl:text-menu">
						<span className="whitespace-nowrap font-normal text-gray-600">
							{formatDate(created_date)}
						</span>
						<span className="text-gray-400 md:px-1">/</span>
						{filteredTags?.slice(0, tagsCount).map((item, i) => (
							<React.Fragment key={i}>
								{i > 0 ? <span className="pr-1 last:hidden">•</span> : null}
								<Link href={getTagLink(item, isResearchFeed)}>
									<Tooltip>
										<TooltipTrigger asChild>
											<span>{item.name || item.tag}</span>
										</TooltipTrigger>
										{item?.tooltip ? (
											<TooltipContent
												variant="overview"
												className="[@media(max-width:768px)]:hidden"
											>
												{parse(item?.tooltip)}
											</TooltipContent>
										) : null}
									</Tooltip>
								</Link>
							</React.Fragment>
						))}
						<span className="md:hidden">
							{filteredTags?.length > 3 ? (
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button variant="text">+{filteredTags?.length - 3} more</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent className="w-56 bg-white">
										<DropdownMenuGroup>
											{filteredTags.slice(3, filteredTags.length).map((item, i) => (
												<React.Fragment key={i}>
													<DropdownMenuItem>
														<Link href={getTagLink(item, isResearchFeed)}>
															<Tooltip>
																<TooltipTrigger asChild>
																	<span>{item.name || item.tag}</span>
																</TooltipTrigger>
																{item?.tooltip && (
																	<TooltipContent
																		variant="overview"
																		className="[@media(max-width:768px)]:hidden"
																	>
																		{parse(item?.tooltip)}
																	</TooltipContent>
																)}
															</Tooltip>
														</Link>
													</DropdownMenuItem>
												</React.Fragment>
											))}
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							) : null}
						</span>
					</div>
					{!isExpanded && view === "full" ? (
						<>
							<MaskContentSection
								condition={showQuickSummary}
								overlayText={<ResearchCardCta hideBody={true} isSecondSummary={isSecondSummary} />}
								hideBody={true}
								content={blurb || summary?.summary?.blurb || fullSummary?.blurb}
							>
								<div className="content mb-5 mt-3 text-start italic text-gray-700 lg:mt-1 xl:text-lg xl:leading-7">
									{parse(blurb || summary?.summary?.blurb || fullSummary?.blurb)}
								</div>
								<StudySummaryActionBar
									showFavorite={isUser}
									headline={headline}
									id={id}
									isFavorite={false}
								/>
							</MaskContentSection>
						</>
					) : null}
				</div>
			</CollapsibleTrigger>
			<CollapsibleContent>
				{loading ? (
					<div className="text-center">
						<Loading />
					</div>
				) : null}

				{version === "v3" ? (
					<section className="max-w-4xl space-y-7 bg-white lg:pt-7 xl:pb-24">
						{blurb || summary?.summary?.blurb || fullSummary?.blurb ? (
							<MaskContentSection
								condition={showQuickSummary}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								textLength={blurb?.length || fullSummary?.blurb?.length}
								section="Quick Summary"
								content={blurb || summary?.summary?.blurb || fullSummary?.blurb}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Quick Summary</p>
									<div className="content">{parse(blurb || fullSummary?.blurb)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.study ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="Key study details"
								content={fullSummary?.study}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Key study details</p>
									<div className="content">{parse(fullSummary?.study)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.bigpicture ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="The big picture"
								content={fullSummary?.bigpicture}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">The big picture</p>
									<div className="content">{parse(fullSummary?.bigpicture)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.results ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="Our take"
								content={fullSummary?.results}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Our take</p>
									<div className="content">{parse(fullSummary?.results)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{showActionbar && showContent ? (
							<StudySummaryActionBar
								showFavorite={isUser}
								headline={headline}
								id={id}
								isFavorite={false}
							/>
						) : null}
					</section>
				) : v2 || version === "v2" ? (
					<section className="max-w-4xl space-y-7 bg-white lg:pt-7 xl:pb-24">
						{blurb || summary?.summary?.blurb || fullSummary?.blurb ? (
							<MaskContentSection
								condition={showQuickSummary}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								textLength={blurb?.length || fullSummary?.blurb?.length}
								section="Quick Summary"
								content={blurb || summary?.summary?.blurb || fullSummary?.blurb}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Quick Summary</p>
									<div className="content">{parse(blurb || fullSummary?.blurb)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.background ? (
							<div className="text-content space-y-4">
								<p className="mb-4 text-lg font-bold xl:text-xl">What was studied?</p>
								<div className="content">{parse(fullSummary?.background)}</div>
							</div>
						) : null}

						{fullSummary?.study_who ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								textLength={fullSummary?.study_who?.length}
								section="Who was studied?"
								content={fullSummary?.study_who}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Who was studied?</p>
									<div className="content">{parse(fullSummary?.study_who)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.study ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="How was it studied?"
								content={fullSummary?.study}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">How was it studied?</p>
									<div className="content">{parse(fullSummary?.study)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.results ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="What were the results?"
								content={fullSummary?.results}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">What were the results?</p>
									<div className="content">{parse(fullSummary?.results)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.bigpicture ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="The big picture"
								content={fullSummary?.bigpicture}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">The big picture</p>
									<div className="content">{parse(fullSummary?.bigpicture)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.additional ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="Anything else I need to know?"
								content={fullSummary?.additional}
							>
								<div className="text-content mt-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Anything else I need to know?</p>
									<div className="content">{parse(fullSummary?.additional)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{showActionbar && showContent ? (
							<StudySummaryActionBar
								showFavorite={isUser}
								headline={headline}
								id={id}
								isFavorite={false}
							/>
						) : null}
					</section>
				) : (
					<section className="max-w-4xl space-y-7 bg-white lg:pt-7 xl:pb-24">
						{blurb || summary?.summary?.blurb || fullSummary?.blurb ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="Quick Summary"
								content={blurb || summary?.summary?.blurb || fullSummary?.blurb}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Quick Summary</p>
									<div className="content">{parse(blurb || fullSummary?.blurb)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.background ? (
							<div className="text-content space-y-4">
								<p className="mb-4 text-lg font-bold xl:text-xl">Background</p>
								<div className="content">{parse(fullSummary?.background)}</div>
							</div>
						) : null}

						{fullSummary?.study ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="The study"
								content={fullSummary?.study}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">The study</p>
									<div className="content">{parse(fullSummary?.study)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.results ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="The results"
								content={fullSummary?.results}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">The results</p>
									<div className="content">{parse(fullSummary?.results)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.additional ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="Note"
								content={fullSummary?.additional}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">Note</p>
									<div className="content">{parse(fullSummary?.additional)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{fullSummary?.bigpicture ? (
							<MaskContentSection
								condition={showContent}
								overlayText={<ResearchCardCta hideBody={false} isSecondSummary={isSecondSummary} />}
								section="The big picture"
								content={fullSummary?.bigpicture}
							>
								<div className="text-content space-y-4">
									<p className="mb-4 text-lg font-bold xl:text-xl">The big picture</p>
									<div className="content">{parse(fullSummary?.bigpicture)}</div>
								</div>
							</MaskContentSection>
						) : null}

						{showActionbar && showContent ? (
							<StudySummaryActionBar
								showFavorite={isUser}
								headline={headline}
								id={id}
								isFavorite={false}
							/>
						) : null}
					</section>
				)}
			</CollapsibleContent>
		</Collapsible>
	);
}
